/* .contact-us-bg-color{
    background: #254660;
} */
.contact-us-particles-container{
    height: calc(119vh - 182px);
    background: #254660
}
.contact-us-footer-container{
    height: 5vh
}
.contact-us-footer-last-part{
    margin-top: 15px;
    margin-bottom: 0
}
.contact-us-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 70%;
    text-align: center;
    /* margin-top: 100px */
}
.contact-us-container h1{
    color: white;
    text-align: center;
}
.contact-us-container p{
    color: lightgray;
    text-align: center
}
.contact-us-container input:nth-child(3), .contact-us-container input:nth-child(4){
    width: 27%;
    margin-right: 11px;
    padding: 0 30px;
    border-radius: 10px;
    border: 1px solid #fff;
    height: 50px;
}
.phone-num-input-container{
    display: flex;
    width: 55%;
    align-items: center;
    margin: 0 auto;
    background: #fff;
    height: 50px;
    margin-top: 20px;
    border-radius: 10px;
}
.phone-num-input-container div{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
}
.phone-num-input-container div input{
    width: 71%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    border: none;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    flex: 3;
    padding-left: 40px;
}
.phone-num-input-container div span{
    flex: 0.5;
    border: 1px solid #fff;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-right: 0;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}
.phone-num-input-container div span i{
    width: 10px;
    color: lightgray;
    cursor: pointer;
}
.phone-num-input-container div span p{
    text-align: center;
    margin-bottom: 0;
    color: black;
    padding-left: 11px
}
.phone-num-input{
    width: 100%!important;
    height: 50px!important;
    border-radius: 10px!important;
    outline: none!important
}
.phone-numbers-list-container{
    background: #fff;
    z-index: 10000;
    position: absolute;
    list-style-type: none;
    text-align: left;
    height: 250px;
    overflow-y: auto;
    width: 230px;
    padding-left: 10px;
    top: 215px;
    border-radius: 10px;
}
.hide-phone-list{
    display: none
}
.phone-numbers-list-container span{
    display: flex;
    list-style-type: none;
    font-size: 14px;
    padding: 6px;
}
/* .drop-down-button{
    border: none!important;
    background: #fff!important;
    border-radius: 10px!important
}
.drop-down-button:hover{
    border-radius: 10px!important
}
.drop-down-container{
    text-align: -webkit-auto;
    margin-top: 0!important
} */
.contact-us-container input:nth-child(5) ,  .contact-us-container textarea{
    width: 55%;
    margin-top: 20px!important;
    padding: 10px 30px;
    border-radius: 10px;
    border: 1px solid #fff;
    height: 150px;
    outline: none;
    resize: none
}
.send-message-btn{
    background: #ed2e79;
    width: 50%;
    margin: 20px auto;
    border-radius: 100px;
    padding: 6px;
    text-align: center;
    cursor: pointer;
}
.send-message-btn button{
    color: #fff;
    background: none;
    border: none;
    outline: none;
}
.send-message-btn img{
    width: 10px
}
.relative{
    position: relative;
}

@media (max-width: 950px){
    .contact-us-container input:nth-child(3){
        width: 100%;
        height: 40px
    }
    .contact-us-container input:nth-child(4){
        width: 100%;
        margin-top: 20px;
        height: 40px;
    }
    .phone-num-input-container{
        width: 100%;
        height: 40px
    }
    .contact-us-container textarea{
        width: 100%;
        height: 110px
    }
    .phone-numbers-list-container{
        top: 255px;
    }
    .phone-num-input{
        height: 35px;
    }
    .send-message-btn{
        margin: 20px 0;
        width: 100%
    }
}

@media (max-width: 590px){
    .phone-num-input-container div span{
        flex: 0.85
    }
}