.custom-container{
  width: 85%!important;
  margin: 0 auto
}

i {
  font-family: FontAwesome!important
}

.back-nav-container{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.back-nav-container i{
  font-size: 18px;
  font-weight: bold;
  color: #ed2e79;
  margin-right: 7px;
}
.back-nav-container p{
  color: #ed2e79;
  margin: 0
}
input{
  outline: none
}

.pointer-cursor{
  cursor: pointer;
}
.content-width{
  max-width: max-content;
}


@media (max-width: 990px){
  .align-center{
      text-align: center!important;
      justify-content: center!important
  }
  .margin-auto{
      margin-left: auto;
      margin-right: auto
  }
  .margin-t{
      margin-top: 25px
  }
}